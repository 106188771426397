export default class VentrataController {
  constructor (config) {
    this.opsdbApiURL = process.env.OPSDB_API
    this.accessToken = process.env.API_ACCESS_TOKEN
    this.opsdbHeaders = { Authorization: 'Bearer ' + this.accessToken }

    this.ventrataEndpoint = process.env.VENTRATA_ENDPOINT
    this.accessToken = process.env.API_ACCESS_TOKEN

    this.ventrataBackOfficeApiKey = config.VENTRATA_BACKOFFICE_API_KEY
    this.ventrataReturnUrl = config.VENTRATA_RETURN_BASE_URL
    this.ventrataApiKey = config.VENTRATA_API_KEY
    this.ventrataEnv = config.VENTRATA_ENV
  }

  errorWrapper (error, context = '') {
    const errorObj = {
      result: 'error',
      error: error.error ?? error.response?.data?.error ?? 'UNKNOWN_ERROR',
      errorMsg: error.errorMessage ?? error.response?.data?.errorMessage ?? 'An unknown error occurred',
      errorCode: error.errorCode ?? error.response?.data?.errorCode ?? 'UNKNOWN_ERROR',
      retryAfter: error.response?.data?.retryAfter,
      status: error.response?.status,
      timestamp: new Date().toISOString()
    }

    if (context) {
      console.error(`${context}:`, errorObj)
    }

    return errorObj
  }

  /**
 * Implements retry logic with exponential backoff for TOO_MANY_REQUESTS errors
 * @param {Function} operation - Async function to retry
 * @param {Function} onRetry - Optional callback for retry attempts
 * @returns {Promise} Result of the operation
 */
  retryHelper = async (operation, onRetry = null) => {
    let retryCount = 0
    const maxRetries = 3
    const maxWaitTime = 10

    const executeWithRetry = async (waitTime = 0) => {
      try {
        if (waitTime > 0) {
          await new Promise(resolve => setTimeout(resolve, waitTime * 1000))
        }

        return await operation()
      } catch (error) {
        const errorObj = this.errorWrapper(error)

        if (errorObj.error === 'TOO_MANY_REQUESTS' && retryCount < maxRetries) {
          retryCount++
          const retryAfter = errorObj.retryAfter || 1
          const nextWaitTime = Math.min(retryAfter, maxWaitTime)

          if (onRetry) {
            onRetry(retryCount, nextWaitTime)
          }

          return executeWithRetry(nextWaitTime)
        }

        if (retryCount > 0) {
          errorObj.retryAttempts = retryCount
        }

        return errorObj
      }
    }

    return executeWithRetry()
  }

  getHeaders (capabilities) {
    return {
      Authorization: `Bearer ${this.ventrataApiKey}`,
      'Octo-Env': this.ventrataEnv,
      'Content-Type': 'application/json',
      'Octo-Capabilities': capabilities
    }
  }

  async sendErrMsgToSlack ($axios, errMsg) {
    const url = `${this.opsdbApiURL}ventrata/slack/message`
    const headers = { Authorization: 'Bearer ' + this.accessToken }
    try {
      const response = await $axios.post(url, {
        msg: errMsg
      }, {
        headers
      })
      return response.data
    } catch (e) {
      return {
        errorMsg: e.message
      }
    }
  }

  async saveApiLog ($axios, payload) {
    const url = `${this.opsdbApiURL}ventrata/api-log`
    const headers = { Authorization: 'Bearer ' + this.accessToken }
    try {
      const response = await $axios.post(url, payload, { headers })
      return response.data
    } catch (e) {
      return {
        errorMsg: e.message
      }
    }
  }

  async handleVentrataError ($axios, error, message, isSlack = false) {
    try {
      console.error(message, this.errorWrapper(error))

      await this.saveApiLog($axios, {
        status: error.response?.status,
        payload: {
          ...error.response?.data,
          url: error.config?.url,
          method: error.config?.method,
          headers: error.config?.headers,
          data: error.config?.data
        },
      })
      if (isSlack) {
        await this.sendErrMsgToSlack($axios, message)
      }
    } catch (e) {
      console.error('Failed to handle Ventrata error', e)
    }
  }

  async reserveBooking ($axios, payload) {
    // need octo/cart to do multi-cart
    const headers = this.getHeaders('octo/content,octo/pricing,octo/cart,octo/questions')

    const operation = async () => {
      try {
        const response = await $axios.post(this.ventrataEndpoint + '/octo/bookings', payload, { headers })
        return response.data
      } catch (e) {
        const message = 'failed to reserve Ventrata Booking'
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async updateBooking ($axios, payload) {
    const headers = this.getHeaders('octo/content,octo/pricing,octo/offers,octo/questions,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages')

    const operation = async () => {
      try {

        const response = await $axios.patch(this.ventrataEndpoint + `/octo/bookings/${payload.uuid}`, payload, { headers })
        return response.data
      } catch (e) {
        const message = `failed to patch Ventrata Booking:${payload.uuid}`
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async listBookings ($axios, supplierReference) {
    const capabilities = 'octo/content,octo/pricing,octo/offers,octo/questions,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages'
    const headers = this.getHeaders(capabilities)
    try {
      const response = await $axios.get(this.ventrataEndpoint + '/octo/bookings', {
        headers,
        params: {
          supplierReference
        }
      })
      return response.data
    } catch (e) {
      const message = `failed to get Ventrata Order - supplierReference: ${supplierReference}`
      this.handleVentrataError($axios, e, message)
      return this.errorWrapper(e)
    }
  }

  async cancelBooking ($axios, bookingUUId, isConfirmed) {
    const capabilities = 'ventrata/checkout,octo/content,octo/pricing,octo/offers,octo/questions,octo/pickups,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages'
    const headers = this.getHeaders(capabilities)
    if (isConfirmed) {
      headers.Authorization = `Bearer ${this.ventrataBackOfficeApiKey}`
    }
    const operation = async () => {
      try {
        const response = await $axios.delete(this.ventrataEndpoint + `/octo/bookings/${bookingUUId}`, { headers })
        return response.data
      } catch (e) {
        const message = `failed to cancel Ventrata Booking: ${bookingUUId}`
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async retrieveOrder ($axios, orderId) {
    const capabilities = 'ventrata/checkout,octo/content,octo/pricing,octo/offers,octo/questions,octo/pickups,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages'
    const headers = this.getHeaders(capabilities)
    try {
      const response = await $axios.get(this.ventrataEndpoint + `/octo/orders/${orderId}`, { headers })
      return response.data
    } catch (e) {
      const message = `failed to retrieve Ventrata Order: ${orderId}`
      this.handleVentrataError($axios, e, message)
      return this.errorWrapper(e)
    }
  }

  async createOrder ($axios, orderInfo) {
    const capabilities = 'octo/content,octo/pricing,octo/offers,octo/questions,octo/pickups,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages'
    const headers = this.getHeaders(capabilities)

    const operation = async () => {
      try {
        const response = await $axios.post(this.ventrataEndpoint + '/octo/orders', orderInfo, { headers })
        return response.data
      } catch (e) {
        const message = 'failed to create Ventrata Order'
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async confirmOrder ($axios, payload) {
    const { orderId, contact, cardPayment } = payload
    const capabilities = 'octo/content,octo/pricing,octo/offers,octo/questions,octo/pickups,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages'
    const headers = this.getHeaders(capabilities)

    const operation = async () => {
      try {
        const response = await $axios.post(this.ventrataEndpoint + `/octo/orders/${orderId}/confirm`, { contact, cardPayment }, { headers })
        return response.data
      } catch (e) {
        const errMsg = e.errorMessage ?? e.response?.data?.errorMessage
        const message = `Failed to confirm Ventrata Order - ${errMsg}`
        this.handleVentrataError($axios, e, message, true)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async patchOrder ($axios, orderId, updateInfo) {
    const capabilities = 'ventrata/checkout,octo/content,octo/pricing,octo/offers,octo/questions,octo/pickups,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages'
    const headers = this.getHeaders(capabilities)

    const originUrl = window.location.origin
    updateInfo = {
      ...updateInfo,
      originUrl,
      recoveryUrl: `${this.ventrataReturnUrl}?recoverOrderId=${orderId}`,
      returnUrl: `${this.ventrataReturnUrl}?returnOrderId=${orderId}`
    }

    const operation = async () => {
      try {
        const response = await $axios.patch(this.ventrataEndpoint + `/octo/orders/${orderId}`, updateInfo, { headers })
        return response.data
      } catch (e) {
        const message = 'failed to Update Ventrata Order'
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async cancelOrder ($axios, orderId, isConfirmed) {
    const capabilities = 'ventrata/checkout,octo/content,octo/pricing,octo/offers,octo/questions,octo/pickups,octo/gifts,octo/extras,octo/cardPayments,octo/cart,octo/resources,octo/packages'
    const headers = this.getHeaders(capabilities)
    if (isConfirmed) {
      headers.Authorization = `Bearer ${this.ventrataBackOfficeApiKey}`
    }
    const operation = async () => {
      try {
        const response = await $axios.delete(this.ventrataEndpoint + `/octo/orders/${orderId}`, { headers })
        return response.data
      } catch (e) {
        const message = `failed to cancel Ventrata Order: ${orderId}`
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async getCheckoutConfig ($axios) {
    const headers = this.getHeaders('ventrata/checkout')
    try {
      const response = await $axios.get(this.ventrataEndpoint + '/octo/ventrata/checkout/config', { headers })
      return response.data
    } catch (e) {
      const message = 'failed to get Ventrata checkout config'
      this.handleVentrataError($axios, e, message)
      return this.errorWrapper(e)
    }
  }

  // should be deprecated
  async getProduct ($axios, productId) {
    const headers = this.getHeaders('octo/content,octo/pricing,octo/questions,octo/extras')

    try {
      const response = await $axios.get(this.ventrataEndpoint + `/octo/products/${productId}`, { headers })
      return response.data
    } catch (e) {
      const message = 'failed to get Ventrata product'
      this.handleVentrataError($axios, e, message)
      return this.errorWrapper(e)
    }
  }

  async getProductCalendar ($axios, payload) {
    const headers = this.getHeaders('octo/content,octo/pricing,octo/extras,octo/offers')

    const operation = async () => {
      try {
        const response = await $axios.post(this.ventrataEndpoint + '/octo/availability/calendar', payload, { headers })
        return response.data
      } catch (e) {
        const message = 'failed to get Ventrata Product Calendar'
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }

  async getAvailability ($axios, payload) {
    const headers = this.getHeaders('octo/content,octo/pricing,octo/pickups,octo/extras,octo/offers,octo/resources')

    const operation = async () => {
      try {
        const response = await $axios.post(this.ventrataEndpoint + '/octo/availability', payload, { headers })
        return response.data
      } catch (e) {
        const message = 'failed to get Ventrata Availability'
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    const response = await this.retryHelper(operation)

    if (response.result === 'error') {
      return []
    }

    return response
  }

  async lookup ($axios, payload) {
    const headers = this.getHeaders('octo/checkin')
    const operation = async () => {
      try {
        const response = await $axios.post(this.ventrataEndpoint + '/octo/checkin/lookup', payload, { headers })
        return response.data
      } catch (e) {
        const message = 'failed to lookup client'
        this.handleVentrataError($axios, e, message)
        throw e
      }
    }

    return this.retryHelper(operation)
  }
}
